import { useReducer } from 'react';

export interface State {
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: Data | null;
  error: Error | null;
}

export interface Action {
  type: 'idle_auth' | 'execute_auth' | 'success_auth' | 'fail_auth';
  payload?: Data;
  error?: Error;
}

interface Data {
  isAuthenticated: boolean;
}

export const initAuthState: State = {
  isIdle: true,
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  error: null,
};

function authReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'idle_auth':
      return initAuthState;
    case 'execute_auth':
      return {
        ...initAuthState,
        isIdle: false,
        isLoading: true,
      };
    case 'success_auth':
      return {
        ...initAuthState,
        isIdle: false,
        isSuccess: true,
        data: action.payload ?? null,
      };
    case 'fail_auth':
      return {
        ...initAuthState,
        isIdle: false,
        isError: true,
        error: action.error ?? null,
      };
    default:
      return initAuthState;
  }
}

export function useAuthReducer() {
  return useReducer(authReducer, initAuthState);
}
