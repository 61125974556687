import { useEffect, useMemo, useRef, useState } from 'react';

interface Props {
  time: number;
}

function ProgressCircle({ time = 0 }: Props) {
  const [ing, setIng] = useState({
    progress: 0,
    percent: 0,
    count: 0,
  });

  const timerIdRef = useRef<NodeJS.Timeout | null>(null);

  const config = useMemo(() => {
    const intervalTime = 200;
    const radius = 22;
    const borderLength = 2 * Math.PI * radius;
    const count = time ? Math.round(time / intervalTime) : 0;

    return {
      intervalTime,
      radius,
      borderLength,
      count,
    };
  }, [time]);

  useEffect(() => {
    if (time > 0)
      timerIdRef.current = setInterval(() => {
        setIng((prev) => {
          if (prev.count >= config.count - 2 && timerIdRef.current) {
            clearTimeout(timerIdRef.current);
          }

          return {
            ...prev,
            progress: prev.progress + config.borderLength / config.count,
            percent: prev.percent + 100 / config.count,
            count: prev.count + 1,
          };
        });
      }, config.intervalTime);

    return () => {
      if (timerIdRef.current) clearTimeout(timerIdRef.current);

      setIng((prev) => ({
        progress: 0,
        percent: 0,
        count: 0,
      }));
    };
  }, [config, time]);

  return (
    <svg width='60' height='60'>
      <text x='30' y='33' fontSize={10} textAnchor='middle' fontFamily='Pretandard' fontWeight={700}>
        {`${ing.percent.toFixed(1)}%`}
      </text>
      <circle cx='30' cy='30' r={config.radius} stroke='gray' fillOpacity={0} strokeWidth={10} />
      <circle
        cx='30'
        cy='30'
        r={config.radius}
        fillOpacity={0}
        stroke='black'
        strokeWidth={9}
        strokeLinecap='round'
        strokeDasharray={config.borderLength}
        strokeDashoffset={config.borderLength - ing.progress}
        transform='rotate(-90, 30, 30)'
        className='transition-all'
      />
    </svg>
  );
}

export default ProgressCircle;
