import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import AnswerCard from './component/AnswerCard';
import DraggableBox from './component/DraggableBox';
import Droppable from './component/Droppable';
import Login from './component/Login';
import ProgressCircle from './component/ProgressCircle';
import { useAuth } from './layout';
import { useAnswersRedcuer, useScriptReducer } from './reducer';

interface Form {
  audioFile: FileList;
  prompts: {
    value: string;
  }[];
  script: {
    text: string;
    timestamp: number[];
    editable: boolean;
  }[];
}

function MainPage() {
  const [auth] = useAuth();

  const [loadTime, setLoadTime] = useState(0);

  const [script, scriptDispatch] = useScriptReducer();

  const [answers, answersDispatch] = useAnswersRedcuer();

  const scrollContainerRef = useRef<HTMLElement>(null);

  const fileRef = useRef<HTMLInputElement | null>(null);

  const { register, handleSubmit, control, watch, setValue, getValues, resetField } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      audioFile: undefined,
      prompts: [
        { value: '후보자가 업무 성과를 도출했던 사례를 한 줄로 요약해줘' },
        { value: '후보자가 업무 성과를 도출했던 사례를 상세히 기술해줘' },
        { value: '후보자의 직무 강점을 요약해서 한 줄로 기재해줘' },
        { value: '후보자가 사람들을 대하는 성격/성향과 사내 대인관계에 대해 한 줄로 기재해줘' },
        { value: '후보자의 리더십 스타일에 대해 한 줄로 기재해줘' },
        { value: '후보자가 본인의 의견을 얘기하고 사람들과 소통하는 커뮤니케이션 스타일에 대해 한 줄로 기재해줘' },
        { value: '후보자의 개선점을 한 줄로 요약해줘' },
        {
          value:
            '후보자가 1. 당시 직장에서 이직한 사유를 한 줄로 요약해줘. 2. 현재 직장에서 이직하려는 사유를 한 줄로 요약해줘',
        },
      ],
      script: [{ text: '', timestamp: [0, 0], editable: false }],
    },
  });

  const {
    fields: fields_prompts,
    move: move_prompts,
    append: append_prompts,
    remove: remove_prompts,
  } = useFieldArray({
    control,
    name: 'prompts',
  });

  const {
    fields: fields_script,
    update: update_script,
    remove: remove_script,
  } = useFieldArray({
    control,
    name: 'script',
  });

  const audioFile = register('audioFile', {
    onChange: onScript,
  });

  const watchAudioFile = watch('audioFile');

  /**********/
  /* handle */
  /**********/

  function handlePromptAdd() {
    if (fields_prompts.length >= 15) return;

    answersDispatch({ type: 'append_answer' });

    append_prompts({ value: '' });
  }

  function handlePromptDelete(index: number) {
    if (fields_prompts.length <= 1) return;

    answersDispatch({ type: 'delete_answers_index', index: index });

    remove_prompts(index);
  }

  function handlePromptReset() {
    resetField('prompts');

    answersDispatch({ type: 'idle_answers' });
  }

  function handleScriptUpdate(script: Form['script'][number], index: number) {
    if (script.editable)
      update_script(index, { ...script, text: getValues(`script.${index}.text`), editable: !script.editable });
    else update_script(index, { ...script, editable: !script.editable });
  }

  function handleScriptDelete(index: number) {
    if (fields_script.length <= 1) return;

    remove_script(index);
  }

  /************/
  /* Computed */
  /************/

  function computedMinSec(time: number) {
    const min = (time / 60).toFixed(0).padStart(2, '0');
    const sec = (time % 60).toFixed(0).padStart(2, '0');

    return `${min}:${sec}`;
  }

  /**************/
  /* Async Data */
  /**************/

  function onScript(e: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>) {
    let files: FileList | null = null;

    if ('dataTransfer' in e) {
      files = e.dataTransfer?.files ?? null;

      setValue('audioFile', files);
    } else {
      files = e.target?.files ?? null;
    }

    try {
      if (files?.[0] == null) {
        throw new Error('파일이 선택되지 않았습니다.');
      }
      if (files[0].type.match(/audio/g) == null) {
        throw new Error('오디오 파일만 가능합니다.');
      }
      const audio = new Audio();

      const audioUrl = URL.createObjectURL(files[0]);

      audio.src = audioUrl;

      audio.addEventListener('loadedmetadata', () => {
        setLoadTime(Math.round(audio.duration / 55) * 1000);

        URL.revokeObjectURL(audioUrl);

        setTimeout(async () => {
          try {
            const formData = new FormData();

            if (files != null) formData.append('file', files[0]);

            scriptDispatch({ type: 'execute_script' });

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/script`, {
              method: 'post',
              body: formData,
            });

            const json = await response.json();

            if (!response.ok) throw new Error(json.message);

            setValue(
              'script',
              json.script.map((val: any) => ({ ...val, editable: false })),
            );

            scriptDispatch({ type: 'success_script', payload: json });
          } catch (error: any) {
            console.error(error);

            setValue('script', [{ text: '', timestamp: [0, 0], editable: false }]);

            scriptDispatch({ type: 'fail_script', error: error as Error });

            if (!('dataTransfer' in e)) e.target.value = '';

            alert(
              error.message === 'Failed to fetch'
                ? '연결이 끊겼거나 현재 사용량이 많습니다.\n잠시 후 재요청 바랍니다.'
                : error.message,
            );
          }
        }, 1000);
      });
    } catch (error: any) {
      console.error(error);

      alert(error.message);
    }
  }

  // async function onAnswers(data: Form, prompt_index?: number, prompt_total_index?: number) {
  //   try {
  //     if (script.isSuccess !== true || script.data?.script == null) throw new Error('스크립트가 존재하지 않습니다.');
  //     if (getValues('prompts').filter((prompt) => prompt.value.length > 0).length <= 0)
  //       throw new Error('입력이 필요합니다.');

  //     const question = prompt_index != null && [data.prompts[prompt_index].value];
  //     const questions = data.prompts.map((prompt) => prompt.value);
  //     const scriptText = getValues('script')
  //       .map((value) => value.text)
  //       .join('');

  //     answersDispatch({ type: 'execute_answers' });

  //     const response = await fetch(`${process.env.REACT_APP_BASE_URL}/answers`, {
  //       method: 'post',
  //       body: new Blob(
  //         [
  //           JSON.stringify({
  //             script: `"${scriptText}"`,
  //             questions: prompt_index != null ? question : questions,
  //           }),
  //         ],
  //         { type: 'application/json' },
  //       ),
  //     });

  //     const json = await response.json();

  //     if (!response.ok) throw new Error(json.message);

  //     prompt_index != null
  //       ? answersDispatch({
  //           type: 'success_answers_index',
  //           payload: json,
  //           index: prompt_index,
  //           totalIndex: prompt_total_index,
  //         })
  //       : answersDispatch({ type: 'success_answers', payload: json });
  //   } catch (error: any) {
  //     console.error(error);

  //     answersDispatch({ type: 'fail_answers', error: error });

  //     alert(
  //       error.message === 'Failed to fetch'
  //         ? error.type == null
  //           ? '연결이 끊겼습니다.'
  //           : '현재 사용량이 많으니 잠시 후 재요청 바랍니다.\n문제가 계속되면 개발자에게 문의해주세요.'
  //         : error.message,
  //     );
  //   }
  // }

  async function onAnswers(data: Form, prompt_index?: number, prompt_total_index?: number) {
    try {
      if (script.isSuccess !== true || script.data?.script == null) throw new Error('스크립트가 존재하지 않습니다.');

      const scriptText = getValues('script')
        .map((value) => value.text)
        .join('');

      if (prompt_index != null) {
        answersDispatch({ type: 'execute_answers' });

        const question = [data.prompts[prompt_index].value];

        if (question[0].length === 0) throw new Error('입력이 필요합니다.');

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/answers`, {
          method: 'post',
          body: new Blob(
            [
              JSON.stringify({
                script: `"${scriptText}"`,
                questions: [data.prompts[prompt_index].value],
              }),
            ],
            { type: 'application/json' },
          ),
        });

        const json = await response.json();

        if (!response.ok) throw new Error(json.message);

        answersDispatch({
          type: 'success_answers_index',
          payload: json,
          index: prompt_index,
          totalIndex: prompt_total_index,
        });
      } else {
        answersDispatch({ type: 'idle_answers' });

        answersDispatch({ type: 'execute_answers' });

        const questions = data.prompts.map((prompt) => prompt.value);

        if (questions.findIndex((question) => question.length <= 0) !== -1) throw new Error('입력이 필요합니다.');

        questions.forEach(async (prompt, prompt_index) => {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/answers`, {
            method: 'post',
            body: new Blob(
              [
                JSON.stringify({
                  script: `"${scriptText}"`,
                  questions: [prompt],
                }),
              ],
              { type: 'application/json' },
            ),
          });

          const json = await response.json();

          if (!response.ok) throw new Error(json.message);

          answersDispatch({
            type: 'multi_success_answers_index',
            payload: json,
            index: prompt_index,
            totalIndex: data.prompts.length,
          });
        });
      }
    } catch (error: any) {
      console.error(error);

      answersDispatch({ type: 'fail_answers', error: error });

      alert(
        error.message === 'Failed to fetch'
          ? '연결이 끊겼거나 현재 사용량이 많습니다.\n잠시 후 재요청 바랍니다.'
          : error.message,
      );
    }
  }

  /*************/
  /* useEffect */
  /*************/

  useEffect(() => {
    // Prevent HTML5 default drag behavior
    document.addEventListener('dragstart', (e) => e.preventDefault());
    // Prevent browsers from opening files when drag over
    document.addEventListener('dragover', (e) => e.preventDefault());
    // Prevent browsers from opening files when drop
    document.addEventListener('drop', (e) => e.preventDefault());
  }, []);

  return (
    <>
      <header className='fixed flex w-full select-none items-center justify-center gap-1 border-b bg-gray-50 p-3 text-lg font-bold shadow-sm'>
        <div className='relative'>Wecruit AI Assistant 🤖</div>
      </header>
      <main id='page' className='min-h-screen min-w-[320px] p-2 pt-16'>
        {auth.isSuccess !== true && auth.data?.isAuthenticated !== true ? (
          <Login />
        ) : (
          <div className='flex flex-wrap gap-2'>
            <form
              onDrop={(e) => {
                if (script.isLoading !== true) onScript(e);
              }}
              className='flex flex-[1_0_300px] flex-col gap-4 rounded-md border-[1px] border-gray-300 bg-gray-50 p-4'
            >
              <button
                disabled={script.isLoading}
                type='button'
                className='w-full rounded-md border-[1px] border-gray-300 bg-gray-800 p-[4px_12px] text-white hover:bg-gray-900 focus:outline-indigo-400 disabled:cursor-no-drop disabled:bg-opacity-20'
                onClick={() => {
                  if (fileRef.current) fileRef.current.click();
                }}
              >
                음성파일 업로드
              </button>
              <input
                name={audioFile.name}
                onChange={audioFile.onChange}
                onBlur={audioFile.onBlur}
                ref={(element) => {
                  audioFile.ref(element);
                  fileRef.current = element;
                }}
                type='file'
                accept='audio/*'
                className='hidden'
              />

              {(() => {
                switch (true) {
                  case script.isLoading === true:
                    return (
                      <div className='flex h-[80vh] flex-col items-center justify-center gap-1'>
                        <ProgressCircle time={loadTime} />
                        {watchAudioFile?.[0] && (
                          <>
                            <div className='text-gray-400'>다른 사용자와 요청이 겹칠 경우 실패할 수 있습니다.</div>
                            <div>{`📁 ${watchAudioFile[0].name} ${(watchAudioFile[0].size / 1048576).toFixed(
                              2,
                            )}MB`}</div>
                          </>
                        )}
                      </div>
                    );
                  case script.isSuccess === true:
                    return (
                      <div className='mx-2 flex h-[80vh] flex-col gap-1 overflow-y-auto border-t p-2'>
                        {fields_script.map((field_script, field_script_index) => (
                          <div key={`script_${field_script_index}`} className='flex items-start gap-2'>
                            <div className='flex-[0_0_max-content] select-none font-sans font-semibold text-gray-600'>{`[ ${computedMinSec(field_script.timestamp[0])} - ${computedMinSec(field_script.timestamp[1])} ]`}</div>
                            {field_script.editable ? (
                              <textarea
                                cols={1}
                                rows={1}
                                {...register(`script.${field_script_index}.text`)}
                                className='flex-[1_1_auto] rounded-md border-[1px] border-gray-300 px-3 py-1 leading-6 tracking-wide'
                              />
                            ) : (
                              <div className='flex-[1_1_auto] leading-6 tracking-wide'>{`${field_script.text}`}</div>
                            )}
                            <button
                              type='button'
                              onClick={() => {
                                handleScriptUpdate(field_script, field_script_index);
                              }}
                              className='flex-[0_0_max-content] select-none rounded-md border-[1px] border-gray-300 bg-gray-100 px-1 text-xs'
                            >
                              {field_script.editable ? '완료' : '수정'}
                            </button>
                            <button
                              type='button'
                              onClick={() => {
                                handleScriptDelete(field_script_index);
                              }}
                              className='flex-[0_0_max-content] select-none rounded-md border-[1px] border-gray-300 bg-gray-100 px-1 text-xs'
                            >
                              삭제
                            </button>
                          </div>
                        )) ?? '-'}
                      </div>
                    );
                  case script.isError === true:
                    return (
                      <p className='flex h-[80vh] items-center justify-center text-red-600'>에러가 발생하였습니다.</p>
                    );
                  default:
                    return (
                      <p className='flex h-[80vh] items-center justify-center whitespace-pre-line text-center text-gray-400'>
                        {
                          '오디오 스크립트가 출력됩니다.\n파일을 드래그하거나, 업로드 버튼을 클릭하여 업로드할 수 있습니다.'
                        }
                      </p>
                    );
                }
              })()}
            </form>

            <form
              onSubmit={handleSubmit((data) => onAnswers(data))}
              className='flex flex-[1_0_300px] flex-col gap-4 rounded-md border-[1px] border-gray-300 bg-gray-50 p-4'
            >
              <section className='flex flex-wrap gap-2'>
                <button
                  disabled={answers.isLoading}
                  type='submit'
                  className='flex-[1_0_auto] rounded-md border-[1px] border-gray-300 bg-gray-800 p-[4px_12px] text-white hover:bg-gray-900 focus:outline-indigo-400 disabled:cursor-no-drop disabled:bg-opacity-20'
                >
                  전체 요청
                </button>
                <button
                  disabled={answers.isLoading}
                  type='button'
                  onClick={handlePromptAdd}
                  className='flex-shrink-0 rounded-md border-[1px] border-gray-300 bg-gray-800 p-[4px_12px] text-white hover:bg-gray-900 focus:outline-indigo-400 disabled:cursor-no-drop disabled:bg-opacity-20'
                >
                  프롬프트 추가하기
                </button>
                <button
                  disabled={answers.isLoading}
                  type='button'
                  onClick={handlePromptReset}
                  className='flex-shrink-0 rounded-md border-[1px] border-gray-300 bg-gray-800 p-[4px_12px] text-white hover:bg-gray-900 focus:outline-indigo-400 disabled:cursor-no-drop disabled:bg-opacity-20'
                >
                  프롬프트 초기화
                </button>
              </section>

              <section ref={scrollContainerRef} className='mx-2 flex h-[80vh] flex-col overflow-y-auto border-t'>
                <Droppable id='0' className='min-h-4 rounded-md transition-all' />
                {fields_prompts.map((field_prompt, field_prompt_index, field_prompt_array) => {
                  return (
                    <DraggableBox
                      key={`prompts_${field_prompt.id}`}
                      onAfterDrop={(id) => {
                        if (field_prompt_index < parseInt(id)) {
                          move_prompts(field_prompt_index, parseInt(id) - 1);
                          answersDispatch({
                            type: 'move_answers',
                            indexA: field_prompt_index,
                            indexB: parseInt(id) - 1,
                          });
                        } else {
                          move_prompts(field_prompt_index, parseInt(id));
                          answersDispatch({
                            type: 'move_answers',
                            indexA: field_prompt_index,
                            indexB: parseInt(id),
                          });
                        }
                      }}
                      scrollContainerRef={scrollContainerRef}
                    >
                      <div className='flex w-full flex-col'>
                        <div className='flex items-start gap-2'>
                          <button
                            type='button'
                            disabled={answers.isLoading}
                            className='draggable flex min-h-9 min-w-10 cursor-grab select-none items-center justify-center rounded-md border-[1px] border-gray-300 bg-gray-800 text-white hover:bg-gray-900 focus:outline-indigo-400 disabled:cursor-no-drop disabled:bg-opacity-20'
                          >
                            {field_prompt_index + 1}
                          </button>

                          <textarea
                            {...register(`prompts.${field_prompt_index}.value`)}
                            cols={1}
                            rows={1}
                            className='min-h-9 flex-[1_0_auto] rounded-md border-[1px] border-gray-300  px-3 py-1 leading-6 tracking-wide'
                          />

                          <button
                            type='button'
                            disabled={answers.isLoading}
                            onClick={() => {
                              handleSubmit((data) => onAnswers(data, field_prompt_index, field_prompt_array.length))();
                            }}
                            className='min-h-9 flex-[0_0_auto] rounded-md border-[1px] border-gray-300 bg-gray-800 p-[4px_12px] text-white hover:bg-gray-900 focus:outline-indigo-400 disabled:cursor-no-drop disabled:bg-opacity-20'
                          >
                            개별 요청
                          </button>

                          <button
                            disabled={answers.isLoading}
                            type='button'
                            onClick={() => handlePromptDelete(field_prompt_index)}
                            className='min-h-9 flex-shrink-0 rounded-md border-[1px] border-gray-300 bg-red-700 p-[4px_12px] text-white hover:bg-red-800 focus:outline-indigo-400 disabled:cursor-no-drop disabled:bg-opacity-20'
                          >
                            삭제
                          </button>
                        </div>

                        {answers.data?.answers[field_prompt_index] &&
                          answers.data.answers[field_prompt_index].map((answer, answer_index) => {
                            return <AnswerCard key={`answers_${answer_index}`} markdown={answer} />;
                          })}

                        <Droppable id={`${field_prompt_index + 1}`} className='min-h-4 rounded-md transition-all' />
                      </div>
                    </DraggableBox>
                  );
                })}
              </section>
            </form>
          </div>
        )}
      </main>
    </>
  );
}

export default MainPage;
