import { useReducer } from 'react';

interface State {
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: Data | null;
  error: Error | null;
}

interface Action {
  type:
    | 'idle_answers'
    | 'execute_answers'
    | 'success_answers_index'
    | 'multi_success_answers_index'
    | 'success_answers'
    | 'fail_answers'
    | 'append_answer'
    | 'delete_answers_index'
    | 'move_answers';
  payload?: Data;
  totalIndex?: number;
  index?: number;
  indexA?: number;
  indexB?: number;
  error?: Error;
}

interface Data {
  answers: string[][];
}

const initAnswersState: State = {
  isIdle: true,
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  error: null,
};

function answersRedcuer(state: State, action: Action): State {
  switch (action.type) {
    case 'idle_answers':
      return initAnswersState;
    case 'execute_answers':
      return {
        ...initAnswersState,
        isIdle: false,
        isLoading: true,
        data: state.data,
      };
    case 'success_answers_index':
      if (action.totalIndex == null || action.index == null || action.payload == null) return initAnswersState;

      const success_answers: string[][] = JSON.parse(
        JSON.stringify(state.data?.answers ?? new Array(action.totalIndex).fill([])),
      );
      success_answers.splice(action.index, 1, action.payload.answers[0]);

      return {
        ...initAnswersState,
        isIdle: false,
        isSuccess: true,
        data: {
          answers: success_answers,
        },
      };
    case 'multi_success_answers_index':
      if (action.totalIndex == null || action.index == null || action.payload == null) return initAnswersState;

      const multi_success_answers: string[][] = JSON.parse(
        JSON.stringify(state.data?.answers ?? new Array(action.totalIndex).fill([])),
      );
      multi_success_answers.splice(action.index, 1, action.payload.answers[0]);

      const index = multi_success_answers.findIndex((v) => v.length === 0);

      return {
        ...initAnswersState,
        isIdle: false,
        isLoading: index === -1 ? false : true,
        isSuccess: true,
        data: {
          answers: multi_success_answers,
        },
      };
    case 'success_answers':
      return {
        ...initAnswersState,
        isIdle: false,
        isSuccess: true,
        data: action.payload ?? null,
      };
    case 'fail_answers':
      return {
        ...initAnswersState,
        isIdle: false,
        isError: true,
        error: action.error ?? null,
      };
    case 'append_answer':
      if (state.data == null) return initAnswersState;

      const append_answer = JSON.parse(JSON.stringify(state.data.answers));
      append_answer.push([]);

      return {
        ...state,
        data: {
          answers: append_answer,
        },
      };
    case 'delete_answers_index':
      if (action.index == null || state.data == null) return initAnswersState;

      const delete_answers = JSON.parse(JSON.stringify(state.data.answers));
      delete_answers.splice(action.index, 1);

      return {
        ...state,
        data: {
          answers: delete_answers,
        },
      };
    case 'move_answers':
      if (action.indexA == null || action.indexB == null || state.data == null) return initAnswersState;

      const move_answers = JSON.parse(JSON.stringify(state.data.answers));
      const removed_answer = move_answers.splice(action.indexA, 1).flat();
      move_answers.splice(action.indexB, 0, removed_answer);

      return {
        ...state,
        data: { answers: move_answers },
      };
    default:
      return initAnswersState;
  }
}

export function useAnswersRedcuer() {
  return useReducer(answersRedcuer, initAnswersState);
}
