import React, { useRef, useState } from 'react';

interface Props {
  children: React.ReactNode;
  onAfterDrop: (id: string) => void;
  scrollContainerRef?: React.MutableRefObject<HTMLElement | null>;
}

function DraggableBox({
  children,
  onAfterDrop,
  scrollContainerRef,
  ...restProps
}: Props & React.ComponentPropsWithoutRef<'div'>) {
  const [location, setLocation] = useState({
    elementWidth: 0,
    isDragging: false,
    initElementX: 0,
    initElementY: 0,
    initWindowX: 0,
    initWindowY: 0,
    windowX: 0,
    windowY: 0,
  });

  const boxRef = useRef<HTMLDivElement>(null);

  const currentDroppableRef = useRef<HTMLElement | null>(null);

  const timerIdRef = useRef<NodeJS.Timeout | null>(null);

  function onMouseDown(event: React.MouseEvent) {
    const className = (event.target as HTMLElement).className;

    if (boxRef.current == null || event.button !== 0 || !className.includes('draggable')) return;

    const { left, top, width } = boxRef.current.getBoundingClientRect();

    setLocation((prev) => ({
      ...prev,
      elementWidth: width,
      isDragging: true,
      initElementX: left,
      initElementY: top,
      initWindowX: event.clientX,
      initWindowY: event.clientY,
    }));

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseleave', onMouseUp);
    boxRef.current.onmouseup = onMouseUp;
  }

  function onMouseMove(this: Document, event: MouseEvent) {
    if (boxRef.current == null || event.button !== 0) return;

    setLocation((prev) => ({
      ...prev,
      windowX: event.clientX - prev.initWindowX,
      windowY: event.clientY - prev.initWindowY,
    }));

    boxRef.current.style.display = 'none';
    let elemBelow = document.elementFromPoint(event.clientX, event.clientY);
    boxRef.current.style.display = 'inherit';

    if (!elemBelow) return;

    let droppableBelow = elemBelow.closest<HTMLElement>('.droppable');
    if (currentDroppableRef.current !== droppableBelow) {
      if (currentDroppableRef.current) {
        currentDroppableRef.current.style.cssText = '';
      }
      currentDroppableRef.current = droppableBelow;
      if (currentDroppableRef.current) {
        currentDroppableRef.current.style.backgroundColor = 'rgb(55, 65, 81)';
        currentDroppableRef.current.style.minHeight = '40px';
      }
    }

    if (!scrollContainerRef?.current) return;

    const { top, bottom } = scrollContainerRef.current.getBoundingClientRect();

    const topArea = event.clientY - top;

    const bottomArea = bottom - event.clientY;

    if (topArea < 30) {
      if (timerIdRef.current) clearInterval(timerIdRef.current);
      timerIdRef.current = setInterval(() => {
        if (!scrollContainerRef?.current) return;

        scrollContainerRef.current.scrollTop -= 5;
      }, 1);
    } else if (bottomArea < 30) {
      if (timerIdRef.current) clearInterval(timerIdRef.current);
      timerIdRef.current = setInterval(() => {
        if (!scrollContainerRef?.current) return;

        scrollContainerRef.current.scrollTop += 5;
      }, 1);
    } else {
      if (timerIdRef.current) clearInterval(timerIdRef.current);
    }
  }

  function onMouseUp(this: GlobalEventHandlers, event: MouseEvent) {
    if (boxRef.current == null || event.button !== 0) return;

    if (timerIdRef.current) clearInterval(timerIdRef.current);

    if (currentDroppableRef.current != null) {
      onAfterDrop(currentDroppableRef.current.id);

      currentDroppableRef.current.style.cssText = '';
    }

    setLocation((prev) => ({
      ...prev,
      isDragging: false,
      initElementX: 0,
      initElementY: 0,
      initWindowX: 0,
      initWindowY: 0,
      windowX: 0,
      windowY: 0,
    }));

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseleave', onMouseUp);
    boxRef.current.onmouseup = null;
  }

  return (
    <div
      ref={boxRef}
      onMouseDown={onMouseDown}
      style={{
        position: location.isDragging ? 'fixed' : undefined,
        width: location.isDragging ? `${location.elementWidth}px` : undefined,
        top: location.isDragging ? `${location.initElementY}px` : undefined,
        left: location.isDragging ? `${location.initElementX}px` : undefined,
        transform: location.isDragging ? `translate(${location.windowX}px, ${location.windowY}px)` : undefined,
      }}
      {...restProps}
    >
      {children}
    </div>
  );
}

export default DraggableBox;

// function vanila() {
//   return (
//     <label
//       onMouseDown={(event) => {
//         if (promptRef.current[field_prompt_index] == null || event.button !== 0) return;

//         let initX = promptRef.current[field_prompt_index]!.getBoundingClientRect().left;
//         let initY = promptRef.current[field_prompt_index]!.getBoundingClientRect().top;

//         let shiftX = event.clientX;
//         let shiftY = event.clientY;

//         promptRef.current[field_prompt_index]!.style.position = 'fixed';
//         promptRef.current[field_prompt_index]!.style.width = '400px';
//         promptRef.current[field_prompt_index]!.style.left = initX + 'px';
//         promptRef.current[field_prompt_index]!.style.top = initY + 'px';

//         function onMouseMove(event: MouseEvent) {
//           promptRef.current[field_prompt_index]!.style.transform =
//             `translate(${event.clientX - shiftX}px, ${event.clientY - shiftY}px)`;

//           promptRef.current[field_prompt_index]!.style.display = 'none';
//           let elemBelow = document.elementFromPoint(event.clientX, event.clientY);
//           promptRef.current[field_prompt_index]!.style.display = 'inherit';

//           if (!elemBelow) return;

//           let droppableBelow = elemBelow.closest<HTMLElement>('.droppable');

//           if (currentDroppable.current !== droppableBelow) {
//             if (currentDroppable.current) {
//               currentDroppable.current.style.cssText = '';
//             }

//             currentDroppable.current = droppableBelow;

//             if (currentDroppable.current) {
//               currentDroppable.current.style.backgroundColor = 'rgb(55, 65, 81)';
//               currentDroppable.current.style.minHeight = '40px';
//             }
//           }

//           const { top, bottom } = droppableContext.current!.getBoundingClientRect();

//           const topArea = event.clientY - top;

//           const bottomArea = bottom - event.clientY;

//           if (topArea < 30) {
//             if (timerId.current) clearInterval(timerId.current);
//             timerId.current = setInterval(() => {
//               droppableContext.current!.scrollTop -= 5;
//             }, 1);
//           } else if (bottomArea < 30) {
//             if (timerId.current) clearInterval(timerId.current);
//             timerId.current = setInterval(() => {
//               droppableContext.current!.scrollTop += 5;
//             }, 1);
//           } else {
//             if (timerId.current) clearInterval(timerId.current);
//           }
//         }

//         function onMouseUp(event: MouseEvent) {
//           if (timerId.current) clearInterval(timerId.current);

//           if (currentDroppable.current != null) {
//             if (field_prompt_index < parseInt(currentDroppable.current.id)) {
//               move_prompts(field_prompt_index, parseInt(currentDroppable.current.id) - 1);
//             } else if (field_prompt_index === parseInt(currentDroppable.current.id)) {
//               swap_prompts(field_prompt_index, parseInt(currentDroppable.current.id));
//             } else {
//               move_prompts(field_prompt_index, parseInt(currentDroppable.current.id));
//             }

//             promptRef.current[field_prompt_index]!.style.cssText = '';
//             currentDroppable.current.style.cssText = '';
//           } else {
//             promptRef.current[field_prompt_index]!.style.cssText = '';
//           }

//           document.removeEventListener('mousemove', onMouseMove);
//           document.removeEventListener('mouseleave', onMouseUp);
//           promptRef.current[field_prompt_index]!.onmouseup = null;
//         }

//         document.addEventListener('mousemove', onMouseMove);
//         document.addEventListener('mouseleave', onMouseUp, { once: true });
//         promptRef.current[field_prompt_index]!.addEventListener('mouseup', onMouseUp, { once: true });
//       }}
//       className='flex min-h-9 min-w-10 cursor-grab select-none items-center justify-center rounded-md border-[1px] border-gray-300 bg-gray-800 text-white hover:bg-gray-900'
//     >
//       {field_prompt_index + 1}
//     </label>
//   );
// }
