import { useForm } from 'react-hook-form';
import { useAuth } from '../layout';

interface Form {
  p: string;
}

function Login() {
  const [auth, authDispatch] = useAuth();

  const { register, handleSubmit } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      p: '',
    },
  });

  async function onLogin(data: Form) {
    try {
      if (authDispatch) authDispatch({ type: 'execute_auth' });

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
        method: 'post',
        body: new Blob(
          [
            JSON.stringify({
              p: data.p,
            }),
          ],
          { type: 'application/json' },
        ),
      });

      const json = await response.json();

      if (!response.ok) throw new Error(json.message);

      if (authDispatch) authDispatch({ type: 'success_auth', payload: json });
    } catch (error: any) {
      console.error(error);

      if (authDispatch) authDispatch({ type: 'fail_auth', error: error });

      alert(error.message);
    }
  }

  return (
    <form onSubmit={handleSubmit(onLogin)} className='flex items-center justify-center gap-2'>
      <label className='text-lg'>🔐</label>
      <input
        {...register('p')}
        type='password'
        disabled={auth.isLoading}
        className='rounded-md border-[1px] border-gray-300 px-3 py-1 leading-6 tracking-wide'
        placeholder='입력'
      />
      {auth.isLoading && <div>로그인 중...</div>}
    </form>
  );
}

export default Login;
