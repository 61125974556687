import React from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

function Droppable({ children, className, ...restProps }: Props & React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div className={`droppable ${className}`} {...restProps}>
      {children}
    </div>
  );
}

export default Droppable;
