import React, { useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';

interface Props {
  markdown: string;
  className?: string;
}

function AnswerCard({ markdown, className, ...restProps }: Props & React.ComponentPropsWithoutRef<'div'>) {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isCopy) {
      timerIdRef.current = setTimeout(() => setIsCopy(false), 1000);
    }

    return () => {
      if (timerIdRef.current) clearTimeout(timerIdRef.current);
    };
  }, [isCopy]);

  return (
    <div
      onMouseEnter={() => {
        setIsMouseEnter(true);
      }}
      onMouseLeave={() => {
        setIsMouseEnter(false);
      }}
      className={`relative mt-2 rounded-lg border-[1px] border-gray-300 bg-gray-200 bg-opacity-70 p-3 ${className}`}
      {...restProps}
    >
      {isMouseEnter && (
        <button
          type='button'
          onClick={(e) => {
            // local, https 환경에서만 동작
            if (e.button === 0) {
              navigator.clipboard.writeText(markdown);
              setIsCopy(true);
            }
          }}
          className='absolute right-2 top-2 rounded-md border-[1px] border-gray-300 bg-gray-800 p-[4px_8px] text-xs text-white hover:bg-gray-900'
        >
          {isCopy ? '복사됨!' : '복사'}
        </button>
      )}
      <Markdown className={'prose-sm'}>{markdown}</Markdown>
    </div>
  );
}

export default AnswerCard;
