import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import MainPage from './Page';
import './index.css';
import { AuthProvider } from './layout';

if (process.env.NODE_ENV === 'production') {
  window.onerror = function (message, url, line, col, error) {
    alert('알 수 없는 에러 발생');
  };
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>,
);
