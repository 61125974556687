import React, { Dispatch, createContext, useContext } from 'react';
import { Action, State, initAuthState, useAuthReducer } from '../reducer';

interface Props {
  children: React.ReactNode;
}

const AuthContext = createContext<[State, Dispatch<Action>]>([initAuthState, () => {}]);

export function AuthProvider({ children }: Props) {
  const [auth, authDispatch] = useAuthReducer();

  return <AuthContext.Provider value={[auth, authDispatch]}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
