import { useReducer } from 'react';

interface State {
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: Data | null;
  error: Error | null;
}

interface Action {
  type: 'idle_script' | 'execute_script' | 'success_script' | 'fail_script';
  payload?: Data;
  error?: Error;
}

interface Data {
  script: Script[];
}

interface Script {
  text: string;
  timestamp: number[];
}

const initScriptState: State = {
  isIdle: true,
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  error: null,
};

function scriptReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'idle_script':
      return initScriptState;
    case 'execute_script':
      return {
        ...initScriptState,
        isIdle: false,
        isLoading: true,
      };
    case 'success_script':
      return {
        ...initScriptState,
        isIdle: false,
        isSuccess: true,
        data: action.payload ?? null,
      };
    case 'fail_script':
      return {
        ...initScriptState,
        isIdle: false,
        isError: true,
        error: action.error ?? null,
      };
    default:
      return initScriptState;
  }
}

export function useScriptReducer() {
  return useReducer(scriptReducer, initScriptState);
}
